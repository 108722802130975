import { template as template_2c7d6bbc02e0450ab559fd7fbfde219b } from "@ember/template-compiler";
const FKControlMenuContainer = template_2c7d6bbc02e0450ab559fd7fbfde219b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
