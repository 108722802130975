import { template as template_77d25478f2ee4d5e939f7b927dbe2a2e } from "@ember/template-compiler";
const FKLabel = template_77d25478f2ee4d5e939f7b927dbe2a2e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
